// custom ck plugin to manage in editor options for figures
import _has from 'lodash/has';
import config from '@/config';
import PCKS_NumberFigureCaptions from '@/services/paper/ck/numberFigureCaptions'
import PS_SaveDocument from '@/services/paper/saveDocument';
import store from '@/store';

(function() {
	CKEDITOR.plugins.add('figureTools', {
		init(editor){
			editor.addCommand('figureDeleteFromCaptionLabel', CKEDITOR.tools.extend({
				exec(editor) {
					const path = editor.elementPath();
					let figure = null;

					if(path && _has(path, 'elements')){
						path.elements.forEach((elementData)=>{
							if(elementData.getName() === 'figure'){
								figure = elementData;
								return false;
							}
						});
					}

					if (!figure){
						return;
					}

					deleteFullFigure(editor, figure);
				}//:exec
			}));//e:figureDeleteFromCaptionLabel

			editor.addCommand('figureDelete', CKEDITOR.tools.extend({
				exec(editor) {
					const path = editor.elementPath();
					let $imgEl = path.contains('img', 1);
	
					if(!$imgEl){
						return;
					}

					let $figureEl = $imgEl.getParent();
					
					deleteFullFigure(editor, $figureEl)
				}//e:exec
			}));//e:addCommand

			editor.addCommand('figureReplace', CKEDITOR.tools.extend({
				exec(editor) {
					const path = editor.elementPath();
					let $imgEl = path.contains('img', 1);
	
					if(!$imgEl){
						return;
					}

					// open a modal to replace this current image
					store.commit('modals/OPEN', 'ReplaceFigure');
					store.commit('modals/SET_MODAL_DATA', {
						imgEl: $imgEl,
					});
				}//e:exec
			}));//e:addCommand:figureReplaceFromComputer
			
			if (editor.addMenuItems) {
				editor.addMenuGroup('figureToolsGroup', 12);
				
				editor.addMenuItems({
					figureReplace: {
						label: 'Replace Figure',
						command: 'figureReplace',
						group: 'figureToolsGroup',
						order: 2
					},
					
					figureDelete: {
						label: 'Delete Figure',
						command: 'figureDelete',
						group: 'figureToolsGroup',
						order: 4
					},

					figureDeleteFromCaptionLabel: {
						label: 'Delete Figure',
						command: 'figureDeleteFromCaptionLabel',
						group: 'figureToolsGroup',
						order: 6
					},
				});
			
                editor.contextMenu.addListener((el, selection, path) => {
					if(path && _has(path, 'lastElement') && 
						(
							path.lastElement.getName() === 'figcaption' ||
							path.lastElement.getName() === 'figure-caption' ||
							path.lastElement.getName() === 'figure-title' || 
							(path.lastElement.getName() === 'p' && path.lastElement.hasClass('figure-notes'))
						)
					){
						return {
							figureDeleteFromCaptionLabel: CKEDITOR.TRISTATE_OFF,
						};
					} else {
						if(path.contains('img', 1)){
							return {
								figureDelete: CKEDITOR.TRISTATE_OFF,
								figureReplace: CKEDITOR.TRISTATE_OFF,
							}
						}
					}
                    return null;
                });
            }//e:if

		},//init

	});//e:plugins.add

	function deleteFullFigure(editor, $figureEl){
		if(editor.$ckEditorType === config.enums.CkEditorType.APPENDIX){
			// appendix delete is a little differnet since i don't clean or recount any of those captions
			if($figureEl){
				$figureEl.remove();

				PS_SaveDocument({
					isMetaDataChanges: false,
					isStealthSave: false,
					message: 'Deleted figure from appendix'
				}).then(()=>{
					window.$vm.emitter.emit('globalToasterOpen',{
						textContent: 'Figure successfully deleted',
					});
				});
			}//e:if

		} else {
			if($figureEl && $figureEl.getName().toLowerCase() === 'figure'){
				// parse the title from this figure element
				let $figureTitleEl = $figureEl.findOne('figure-title');
				if(!$figureTitleEl){
					// figure element wasn't found using custom tag, maybe it's the old span way
					$figureTitleEl = $figureEl.findOne('span.figure-title');
				}
				
				if($figureTitleEl){
					let figureTitle = $figureTitleEl.getText();

					$figureEl.remove();

					// send a signal to renumber the figure captions
					PCKS_NumberFigureCaptions({
						ckEditor: 'all'
					}).then(()=>{
						PS_SaveDocument({
							isMetaDataChanges: false,
							isStealthSave: false,
							message: 'Deleted figure ' + figureTitle
						}).then(()=>{
							window.$vm.emitter.emit('globalToasterOpen',{
								textContent: 'Figure successfully deleted',
							});
						});
						
					});
				}//e:if
			}//e:if
		}//e:if

	}//e:deleteFullFigure

})();