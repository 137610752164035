/*
- Custom Plugin to manage the Table context menu
*/
import _has from 'lodash/has';
import {deleteColumns} from './tableTools_deleteColumns';
import {deleteRows} from './tableTools_deleteRows';
import {getSelectedCells} from './tableTools_getSelectedCells';
import {insertColumn} from './tableTools_insertColumn';
import {insertRow} from './tableTools_insertRow';
import {placeCursorInCell} from './tableTools_placeCursorInCell';
import PCKS_NumberTableCaptions from '@/services/paper/ck/numberTableCaptions'
import PS_SaveDocument from '@/services/paper/saveDocument';

(() => {
	let setPath = '';

	CKEDITOR.plugins.add('tableTools', {
		requires: 'contextmenu',
		init(editor) {
			let tabSpaces = editor.config.tabSpaces || 0;
			let tabText = '';

			while (tabSpaces--){
				tabText += '\xa0';
			}

			editor.on('key', (ev) => {
				if (ev.data.keyCode == 9) {
					// TAB
					let sel = editor.getSelection();
					setPath = new CKEDITOR.dom.elementPath( sel.getCommonAncestor(), sel.root );
					if ((setPath.contains( { td: 1, th: 1 }, 1 ))) {
						editor.execCommand('selectNextTableCell');
						ev.cancel();
					}
				} else if (ev.data.keyCode == (CKEDITOR.SHIFT + 9)) {
					// SHIFT + TAB
					let sel = editor.getSelection();
					setPath = new CKEDITOR.dom.elementPath( sel.getCommonAncestor(), sel.root );
					if ((setPath.contains( { td: 1, th: 1 }, 1 ))) {
						editor.execCommand('selectPreviousTableCell');
						ev.cancel();
					}
				}
			});//e:on:key

			editor.addCommand('selectNextTableCell', selectTableCell());
			editor.addCommand('selectPreviousTableCell', selectTableCell(true));

			editor.addCommand('colDelete', CKEDITOR.tools.extend({
				exec(editor) {
					let selection = editor.getSelection();
					let element = deleteColumns(selection);

					if (element) {
						placeCursorInCell( element, true );
					}

				}
			}));

			editor.addCommand('colInsertLeft', CKEDITOR.tools.extend({
				exec(editor) {
					let selection = editor.getSelection();
					let cells = getSelectedCells(selection);
					insertColumn(cells, true);
				}
			}));
			
			editor.addCommand('colInsertRight', CKEDITOR.tools.extend({
				exec(editor) {
					let selection = editor.getSelection();
					let cells = getSelectedCells(selection);
					insertColumn(cells);
				}
			}));

			editor.addCommand('rowDelete', CKEDITOR.tools.extend({
				exec(editor) {
					let selection = editor.getSelection();
					let cursorPosition = deleteRows(selection);
					
					if (cursorPosition) {
						placeCursorInCell(cursorPosition);
					}
				}
			}));

			editor.addCommand('rowInsertAbove', CKEDITOR.tools.extend({
				exec(editor) {
					let selection = editor.getSelection();
					let cells = getSelectedCells(selection);
					insertRow(cells, true);
				}
			}));

			editor.addCommand('rowInsertBelow', CKEDITOR.tools.extend({
				exec(editor) {
					let selection = editor.getSelection();
					let	cells = getSelectedCells(selection);
					insertRow(cells);
				}
			}));

			editor.addCommand('tableDeleteFromCaptionLabel', CKEDITOR.tools.extend({
				exec: (editor)=>{
					const path = editor.elementPath();
					let table = null;
					
					if(path && _has(path, 'elements')){
						path.elements.forEach((elementData)=>{
							if(elementData.getName() === 'table'){
								table = elementData;
								return false;
							}
						});
					}
					if (!table){
						return;
					}

					deleteFullTable(editor, table);
				}
			}));


			editor.addCommand('tableDelete', CKEDITOR.tools.extend({
				exec: (editor)=>{
					const path = editor.elementPath();
					let table = path.contains('table', 1);
	
					if (!table){
						return;
					}
	
					deleteFullTable(editor, table);
				}
			}));


			// register context menu option for editing widget.
			if (editor.addMenuItems) {
				editor.addMenuGroup('tableToolsInsert', 10);
				editor.addMenuGroup('tableToolsDelete', 12);

				editor.addMenuItems({
					rowInsertAbove: {
						label: 'Insert Row Above',
						group: 'tableToolsInsert',
						command: 'rowInsertAbove',
						order: 2
					},
					rowInsertBelow: {
						label: 'Insert Row Below',
						group: 'tableToolsInsert',
						command: 'rowInsertBelow',
						order: 4
					},
					colInsertLeft: {
						label: 'Insert Column Left',
						group: 'tableToolsInsert',
						command: 'colInsertLeft',
						order: 6
					},
					colInsertRight: {
						label: 'Insert Column Right',
						group: 'tableToolsInsert',
						command: 'colInsertRight',
						order: 6
					},
					rowDelete: {
						label: 'Delete Row',
						group: 'tableToolsDelete',
						command: 'rowDelete',
						order: 2
					},
					colDelete: {
						label: 'Delete Column',
						command: 'colDelete',
						group: 'tableToolsDelete',
						order: 4
					},

					tableDelete: {
						label: 'Delete Table',
						command: 'tableDelete',
						group: 'tableToolsDelete',
						order: 6
					},
					tableDeleteFromCaptionLabel: {
						label: 'Delete Table',
						command: 'tableDeleteFromCaptionLabel',
						group: 'tableToolsDelete',
						order: 8
					},
					
					
				});
			}

			// attach context menu listeners
			if (editor.contextMenu) {
				editor.contextMenu.addListener(( element, selection, path ) => {
					if(path && _has(path, 'lastElement') && 
						(
							path.lastElement.getName() === 'caption' ||
							path.lastElement.getName() === 'table-caption' ||
							path.lastElement.getName() === 'table-title'
						)
					){
						return {
							tableDeleteFromCaptionLabel: CKEDITOR.TRISTATE_OFF,
						};
					} else {
						let cell = path.contains( { 'td': 1, 'th': 1 }, 1 );
						if (cell && !cell.isReadOnly()) {
							return {
								colDelete: CKEDITOR.TRISTATE_OFF,
								colInsertLeft: CKEDITOR.TRISTATE_OFF,
								colInsertRight: CKEDITOR.TRISTATE_OFF,
								rowDelete: CKEDITOR.TRISTATE_OFF,
								rowInsertAbove: CKEDITOR.TRISTATE_OFF,
								rowInsertBelow: CKEDITOR.TRISTATE_OFF,
								tableDelete: CKEDITOR.TRISTATE_OFF,
							};
						}
					}

					return null;
				});

				// editor.contextMenu.addListener((element, selection, path) => {
				// 	// let captionAndLabel = path.contains( { 'table': 1, 'caption': 1 }, 1 );
					
				// 	// console.log(captionAndLabel);

					

				// 	return null;
				// });
			}
		

		},//e:init

	});//e:plugins.add

	function deleteFullTable(editor, tableElement){
		// If the table's parent has only one child remove it as well (unless it's a table cell, or the editable element)
		//(https://dev.ckeditor.com/ticket/5416, https://dev.ckeditor.com/ticket/6289, https://dev.ckeditor.com/ticket/12110)
		let parent = tableElement.getParent();
		let editable = editor.editable();

		if (
			parent.getChildCount() == 1 &&
			!parent.is('td', 'th') &&
			!parent.equals(editable)
		){
			tableElement = parent;
		}

		// parse the title from this figure element
		let tableTitle = tableElement.findOne('table-title').getText() || '';
			
		let range = editor.createRange();
		range.moveToPosition(tableElement, CKEDITOR.POSITION_BEFORE_START);
		tableElement.remove();
		range.select();

		PCKS_NumberTableCaptions({
			ckEditor: 'all'
		}).then(()=>{
			PS_SaveDocument({
				message: 'Deleted table ' + tableTitle,
			});
		});
	}//e:deleteFullTable

	function selectTableCell(backward) {
		return {
			editorFocus: false,
			canUndo: false,
			modes: { wysiwyg: 1 },
			exec(editor) {

				let cell = setPath.contains({ td: 1, th: 1 }, 1 );
				
				var resultRange = editor.createRange(),
					next = CKEDITOR.tools.tryThese( function() {
						var row = cell.getParent(),
							next = row.$.cells[ cell.$.cellIndex + ( backward ? -1 : 1 ) ];

						// Invalid any empty value.
						next.parentNode.parentNode;
						return next;
					}, function() {
						var row = cell.getParent(),
							table = row.getAscendant( 'table' ),
							nextRow = table.$.rows[ row.$.rowIndex + ( backward ? -1 : 1 ) ];

						return nextRow.cells[ backward ? nextRow.cells.length - 1 : 0 ];
					} );

				// Clone one more row at the end of table and select the first newly established cell.
				if ( !( next || backward ) ) {
					var table = cell.getAscendant( 'table' ).$,
						cells = cell.getParent().$.cells;

					var newRow = new CKEDITOR.dom.element( table.insertRow( -1 ), editor.document );

					for ( var i = 0, count = cells.length; i < count; i++ ) {
						var newCell = newRow.append( new CKEDITOR.dom.element( cells[ i ], editor.document ).clone( false, false ) );
						newCell.appendBogus();
					}

					resultRange.moveToElementEditStart( newRow );
				} else if ( next ) {
					next = new CKEDITOR.dom.element( next );
					resultRange.moveToElementEditStart( next );
					// Avoid selecting empty block makes the cursor blind.
					if ( !( resultRange.checkStartOfBlock() && resultRange.checkEndOfBlock() ) )
						resultRange.selectNodeContents( next );
				} else {
					return true;
				}

				resultRange.select( true );
				return true;
			}
		};
	}//e:selectTableCell

})();

/**
 * Creates a two-dimension array that reflects the actual layout of table cells,
 * with cell spans, with mappings to the original `td` elements.
 *
 * It could also create a map for the specified fragment of the table.
 *
 * @param {CKEDITOR.dom.element} table
 * @param {Number} startRow Row index from which the map should be created.
 * @param {Number} startCell Cell index from which the map should be created.
 * @param {Number} endRow Row index to which the map should be created.
 * @param {Number} endCell Cell index to which the map should be created.
 * @member CKEDITOR.tools
 */
CKEDITOR.tools.buildTableMap = function( table, startRow, startCell, endRow, endCell ) {
	var aRows = table.$.rows;

	startRow = startRow || 0;
	startCell = startCell || 0;
	endRow = typeof endRow === 'number' ? endRow : aRows.length - 1;
	endCell = typeof endCell === 'number' ? endCell : -1;

	// Row and Column counters.
	var r = -1,
		aMap = [];

	for ( var i = startRow; i <= endRow; i++ ) {
		r++;
		!aMap[ r ] && ( aMap[ r ] = [] );

		var c = -1;

		for ( var j = startCell; j <= ( endCell === -1 ? ( aRows[ i ].cells.length - 1 ) : endCell ); j++ ) {
			var oCell = aRows[ i ].cells[ j ];

			if ( !oCell ) {
				break;
			}

			c++;
			while ( aMap[ r ][ c ] ) {
				c++;
			}

			var iColSpan = isNaN( oCell.colSpan ) ? 1 : oCell.colSpan,
				iRowSpan = isNaN( oCell.rowSpan ) ? 1 : oCell.rowSpan;

			for ( var rs = 0; rs < iRowSpan; rs++ ) {
				if ( i + rs > endRow ) {
					break;
				}

				if ( !aMap[ r + rs ] ) {
					aMap[ r + rs ] = [];
				}

				for ( var cs = 0; cs < iColSpan; cs++ ) {
					aMap[ r + rs ][ c + cs ] = aRows[ i ].cells[ j ];
				}
			}

			c += iColSpan - 1;

			if ( endCell !== -1 && c >= endCell ) {
				break;
			}
		}
	}
	return aMap;
};

