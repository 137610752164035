// extracted from https://ckeditor.com/cke4/addon/tabletools

import {getSelectedCells} from './tableTools_getSelectedCells';

export const deleteRows = (selectionOrRow) => {
	if ( selectionOrRow instanceof CKEDITOR.dom.selection ) {
		var ranges = selectionOrRow.getRanges(),
			cells = getSelectedCells( selectionOrRow ),
			firstCell = cells[ 0 ],
			table = firstCell.getAscendant( 'table' ),
			map = CKEDITOR.tools.buildTableMap( table ),
			startRow = cells[ 0 ].getParent(),
			startRowIndex = startRow.$.rowIndex,
			lastCell = cells[ cells.length - 1 ],
			endRowIndex = lastCell.getParent().$.rowIndex + lastCell.$.rowSpan - 1,
			rowsToDelete = [];

		selectionOrRow.reset();

		// Delete cell or reduce cell spans by checking through the table map.
		for ( var i = startRowIndex; i <= endRowIndex; i++ ) {
			var mapRow = map[ i ],
				row = new CKEDITOR.dom.element( table.$.rows[ i ] );

			for ( var j = 0; j < mapRow.length; j++ ) {
				var cell = new CKEDITOR.dom.element( mapRow[ j ] ),
					cellRowIndex = cell.getParent().$.rowIndex;

				if ( cell.$.rowSpan == 1 ) {
					cell.remove();
				}
				// Row spanned cell.
				else {
					// Span row of the cell, reduce spanning.
					cell.$.rowSpan -= 1;
					// Root row of the cell, root cell to next row.
					if ( cellRowIndex == i ) {
						var nextMapRow = map[ i + 1 ];
						nextMapRow[ j - 1 ] ? cell.insertAfter( new CKEDITOR.dom.element( nextMapRow[ j - 1 ] ) ) : new CKEDITOR.dom.element( table.$.rows[ i + 1 ] ).append( cell, 1 );
					}
				}

				j += cell.$.colSpan - 1;
			}

			rowsToDelete.push( row );
		}

		var rows = table.$.rows;

		// After deleting whole table, the selection would be broken,
		// therefore it's safer to move it outside the table first.
		ranges[ 0 ].moveToPosition( table, CKEDITOR.POSITION_BEFORE_START );

		// Where to put the cursor after rows been deleted?
		// 1. Into next sibling row if any;
		// 2. Into previous sibling row if any;
		// 3. Into table's parent element if it's the very last row.
		var cursorPosition = new CKEDITOR.dom.element( rows[ endRowIndex + 1 ] || ( startRowIndex > 0 ? rows[ startRowIndex - 1 ] : null ) || table.$.parentNode );

		for ( i = rowsToDelete.length; i >= 0; i-- ) {
			deleteRows( rowsToDelete[ i ] );
		}

		// If all the rows were removed, table gets removed too.
		if ( !table.$.parentNode ) {
			ranges[ 0 ].select();
			return null;
		}

		return cursorPosition;
	} else if ( selectionOrRow instanceof CKEDITOR.dom.element ) {
		table = selectionOrRow.getAscendant( 'table' );

		if ( table.$.rows.length == 1 ) {
			table.remove();
		} else {
			selectionOrRow.remove();
		}
	}

	return null;
};