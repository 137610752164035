/*
- Fake Citations can be in the Outline Editor
*/

import _has from 'lodash/has';
import OS_SaveContent from '@/services/paper/outline/saveContent';
import router from '@/router';
import store from '@/store';

(() => {
	'use strict';

	CKEDITOR.plugins.add('outlineCitation', {
		icons: 'delete,edit,showinpaper',
		requires: "widget",
		
        init(editor) {
			editor.ui.addButton( 'OutlineCitation', {
				label: 'Insert Citation',
				command: 'outlineCitation',	// match the name of the widget you add
			});

			editor.widgets.add('outlineCitation', {
				allowedContent: {
					span: {
						attributes: 'citation-data, data-citation-unique-id, data-reference-unique-id, data-reference-id',
						classes: '!perrla_citation',
					},
				},
				
				template: '<span class="perrla_citation">O</span>',

				data() {
					// Insert					
					if(this.data.citationUniqueID){
						this.element.setAttribute("data-citation-unique-id", this.data.citationUniqueID);
					}
					if(this.data.referenceUniqueID){
						this.element.setAttribute("data-reference-unique-id", this.data.referenceUniqueID);
					}
					if(this.data.referenceID){
						this.element.setAttribute("data-reference-id", this.data.referenceID);
					}
					if(this.data.valueFirst){
						// this.element.setHtml(this.data.valueFirst);
						if(store.state.paperEdit.meta.UseNewCitationMode){
							this.element.setHtml(this.data.valueFirst.slice(1, -1));
						} else {
							this.element.setHtml(this.data.valueFirst);
						}
					}
					if(this.data.citationDataBase64){
						this.element.setAttribute("citation-data", this.data.citationDataBase64);
					}
				},
			
				draggable: true,

				edit() {
					openCitationEditDrawer(editor);
				},

				init() {
					// Add widget editing option to its context menu.
					this.on('contextMenu', function (evt) {
						evt.data.citation_delete = CKEDITOR.TRISTATE_OFF;
						evt.data.citation = CKEDITOR.TRISTATE_OFF;
					});
				},//init

				// requiredContent: 'span(perrla_citation)',

				upcast(el) {
					if (el.name === 'span' && el.hasClass('perrla_citation')) {
						return el;
					}
				},//e:upcast

			});//e:editor.widgets.add

			// CUSTOM Edit Citation in context menu
			editor.addCommand('cmdCitationEdit', CKEDITOR.tools.extend({
				exec(editor) {
					openCitationEditDrawer(editor);
                }//e:exec
			}));//e:addCommand:cmdCitationEdit

			// CUSTOM Delete Citation in context menu
			editor.addCommand('cmdCitationDelete', CKEDITOR.tools.extend({
				exec(editor) {
					let path = editor.elementPath();
					let $citationEl = path.contains('span', 1);

					if (!$citationEl){
						return false;
					}

					if($citationEl.getFirst() && $citationEl.hasClass('cke_widget_outlineCitation')){
						let _citationElGetFirst = $citationEl.getFirst();					
						
					
						// Single
						let citationUniqueId = _citationElGetFirst.getAttribute('data-citation-unique-id');
						
						// remove citation from vuex store (use outline.citationObjects)
						// let findCitationIndex = store.state.paperEdit.citations.inPaper.findIndex((citation)=>{
						// 	return citation.citationData.citationUniqueID.toUpperCase() === citationUniqueId.toUpperCase();
						// });

						// if(findCitationIndex !== -1){
						// 	store.commit('paperEdit/citations/REMOVE_AT_INDEX', findCitationIndex);
						// }
				

						// remove citation widget from paper body
						if (_citationElGetFirst && _citationElGetFirst.getParent()) {
							_citationElGetFirst.getParent().remove();

						} else {
							console.log('There was a problem removing the citation from this paper');
						}

						OS_SaveContent().then(()=>{
							window.$vm.emitter.emit('setOutlineEditorHeight');

							if(router.currentRoute.name !== 'OutlineReferencesCitations'){
								router.push({
									name: 'OutlineReferencesCitations',
									params: {
										citationUniqueId: citationUniqueId,
									}
								}).catch(()=>{});
							}
						});

					}//e:if

				}//e:exec
			}));

			// Register context menu option for editing widget.
			if (editor.addMenuItems) {
				editor.addMenuGroup('fakeCitation', 10);

				editor.addMenuItems({
					citation: {
						icon: 'edit',
						label: 'Edit Footnote Citation',
						group: 'fakeCitation',
						command: 'cmdCitationEdit',
						order: 5
					},
					citation_delete: {
						icon: 'delete',
						label: 'Delete Footnote',
						group: 'fakeCitation',
						command: 'cmdCitationDelete',
						order: 7
					},
				});
			}//e:if:editor.addMenuItems

		}//e:init
	});//e:CKEDITOR.plugins.add

	function openCitationEditDrawer(editor){
		let path = editor.elementPath();
		let $citationEl = path.contains('span', 1);

		if (!$citationEl){
			return false;
		}
		
		if($citationEl.hasClass('cke_widget_outlineCitation')){

			let citationData = $citationEl.getFirst().$.getAttribute('citation-data') || '';
			let citationUniqueId = $citationEl.getFirst().$.getAttribute('data-citation-unique-id') || '';
			
			try {
				let citationDataDecode = window.atob(citationData);

				store.commit('researchNotes/SET_TEMP_CITATION_DATA', citationDataDecode);

				if(store.state.paperEdit.config.renderCitationsAsFootnotes){
					if(window.$vm.$route.name === 'OutlineCitationEdit' && window.$vm.$route.params.citationUniqueId === citationUniqueId){
						// don't change route - user is already 'there'
						
					} else {
						router.push({
							name: 'OutlineCitationEdit',
							params: {
								citationUniqueId: citationUniqueId,
							}
						}).catch(()=>{});
					}

				} else {
					if(window.$vm.$route.name === 'OutlineCitationEdit' && window.$vm.$route.params.citationUniqueId === citationUniqueId){
						// don't change route - user is already 'there'
						
					} else {
						router.push({
							name: 'OutlineCitationEdit',
							params: {
								citationUniqueId: citationUniqueId,
							}
						}).catch(()=>{});
					}
				}

			} catch {
				return false;
			}
		}//e:if

	}//e:openCitationEditDrawer
})();