/*
- Builds and returns a config object that the Abstract and Body of the paper can share parts of
-- when you download a new version ckEditor, there will be no plugins each one is a separte file now
*/

import config from '@/config';
import store from '@/store';

export default {
	getSettings(editorId){
		let returnConfig = {};
	
		let styleClassArray = ['style-default'];
		
		styleClassArray.push(store.state.paperEdit.config.ckEditorClass);

		switch(parseInt(store.state.paperEdit.meta.FontFamily)){
			case 1:
				styleClassArray.push('font-family-arial');
				break;
			case 2:
				styleClassArray.push('font-family-courier');
				break;
			case 4:
				styleClassArray.push('font-family-trebuchet');
				break;
			case 5:
				styleClassArray.push('font-family-verdana');
				break;
			default: 
				styleClassArray.push('font-family-times-new-roman');
				break;
		}
		

		// Abstract (single)
		if(editorId === 'ckPaperAbstract'){
			styleClassArray.push('instance-abstract');
			returnConfig.bodyClass = styleClassArray.join(' ');
			returnConfig.bodyId = 'body_ckPaperAbstract';
			returnConfig.editorplaceholder = 'Start typing your abstract here...'
		}
		
		// Annotations (multi)
		if(editorId === 'ckAnnotations'){
			styleClassArray.push('style-annotation');
			returnConfig.bodyClass = styleClassArray.join(' ');
			returnConfig.editorplaceholder = 'Start typing your annotation here...';
		}

		// Appendix (multi)
		if(editorId === 'ckAppendices'){
			returnConfig.bodyClass = styleClassArray.join(' ');
			returnConfig.editorplaceholder = 'Type your appendix here...';
		}

		// Authors Note (single - APA7 Pro Papers Only)
		if(editorId === 'ckPaperAuthorNote'){
			styleClassArray.push('style-authors-note');
			returnConfig.bodyClass = styleClassArray.join(' ');
			returnConfig.editorplaceholder = "Type the Author's note here. APA 7 includes four possible topics to cover in an Author's Note: ORCID iDs for the authors, changes of affiliation, disclosures and acknowledgements, & contact information. For more information, see the APA 7th Edition Manual, Section 2.7."
		}

		// Body (singe)
		if(editorId === 'ckPaperBody'){
			returnConfig.bodyClass = styleClassArray.join(' ');
			returnConfig.bodyId = 'body_ckPaperBody';
			returnConfig.editorplaceholder = 'Start typing your paper here...'
		}
		
		// Footnotes (singe)
		if(editorId === 'ckPaperFootnotes'){
			styleClassArray.push('style-footnotes');

			returnConfig.bodyClass = styleClassArray.join(' ');
			returnConfig.bodyId = 'body_ckPaperFootnotes';
			returnConfig.editorplaceholder = 'Footnotes...'
		}


		returnConfig.contentsCss = (config.isDev) ? 'perrla-spa-paper-styles.css?v='+__webpack_hash__ : '/Content/perrla-spa-paper-styles.css?v='+__webpack_hash__;
		returnConfig.customConfig = '';
		returnConfig.format_tags = 'p;h1;h2;h3;h4;h5';
		returnConfig.resize_enabled = false;
		
		if(config.isLive || config.isTest){
			returnConfig.disableNativeSpellChecker = true;
		} else {
			// Not Live / Not Test - use Native Spellchecker
			returnConfig.disableNativeSpellChecker = false;
		}
	
		// Outline
		if(editorId === 'ckPaperOutline'){
			// Outline Editor Only in the Organize Tab
			styleClassArray.push('style-outline');
			returnConfig.bodyClass = styleClassArray.join(' ');
			returnConfig.bodyId = 'body_ckOutline';
			
			returnConfig.extraPlugins = [
				'autocorrect',
				'basicstyles',
				'button',
				'contextmenu',
				'enterkey',
				'justify',
				'fakeobjects',
				'lineutils',
				'list',
				'outlineDropContent',
				'outlineCitation',
				'panel',
				'preview',
				'printDocument',
				'removeformat',
				'saveOutline',
				'toolbar',
				'undo',
				'widget',
				'widgetselection',
				'wysiwygarea',
			];

			// add the citation context menu
			if(
				store.state.customer.citationAutoInsert &&
				store.state.paperEdit.meta.UseNewCitationMode
			){
				returnConfig.extraPlugins.push('citationContextMenu');
			}

			if(config.isLive){
				// Live
				if(store.state.customer.isImpersonating){
					returnConfig.extraPlugins.push('sourcearea');	
				}
			} else {
				// DevDev | Dev | Test
				returnConfig.extraPlugins.push('sourcearea');
			}

			returnConfig.extraAllowedContent = {
				span: {
					attributes: 'citation-data, data-citation-unique-id, data-reference-id',
					classes: '!perrla_citation',
				},
			}

			returnConfig.pasteFilter = {
				span: {
					attributes: 'citation-data, data-citation-unique-id, data-reference-id',
					classes: '!perrla_citation',
				},
			}

			returnConfig.autocorrect_recognizeUrls = false;
			returnConfig.autocorrect_formatBulletedLists = false;
			returnConfig.autocorrect_formatNumberedLists = false;
		
		} else if(editorId === 'ckPaperFootnotes'){
			// Footnotes Text Editor Only in the Footnote Text Modal
			styleClassArray.push('style-footnotes');
			returnConfig.bodyClass = styleClassArray.join(' ');
			returnConfig.bodyId = 'body_ckFootnotes';

			returnConfig.extraPlugins = [
				'autocorrect',
				'basicstyles',
				'button',
				'contextmenu',
				'enterkey',
				'justify',
				'fakeobjects',
				'lineutils',
				'list',
				'panel',
				'perrlaFootnotes',
				'removeformat',
				'saveFootnotes',
				'toolbar',
				'undo',
				'widget',
				'widgetselection',
				'wysiwygarea',
			];

			returnConfig.extraAllowedContent = {
				span: {
					attributes: 'citation-data, data-citation-unique-id, data-reference-id',
					classes: '!perrla_citation',
				},
			}

			returnConfig.pasteFilter = {
				span: {
					attributes: 'citation-data, data-citation-unique-id, data-reference-id',
					classes: '!perrla_citation',
				},
			}

		} else {
			// Every other ckInstnace
			returnConfig.autoGrow_onStartup = true;
			returnConfig.autoGrow_minHeight = 135;

			returnConfig.extraAllowedContent = {
				blockquote:{},
				caption:{},
				figure: {
					attributes: 'title',
					classes: 'image,float-left,float-start,float-right,float-end,center,right'
				},
				figcaption:{},
				'figure-caption':{},
				'figure-title':{},
				h1:{
					classes: 'appendix-title'
				},
				h2:{},
				h3:{},
				h4:{},
				h5:{},
				img: {
					attributes: ['!src'],
					styles: 'width'
				},
				p:{
					classes: 'blockquote-indent,figure-notes,has-span-h5,no-indent',
				},
				span: {
					attributes: 'title',
					classes: 'figure-caption,figure-title,h3,h4,h5,table-caption,table-title,perrla_citation',
					styles: 'display'
				},	
				table:{
					attributes: 'align,border,cellpadding,cellspacing,summary',
					styles: 'width,height,border-collapse',
				},
				'table-caption':{},
				'table-title':{},
				tbody:{},
				tfoot:{},
				th:{},
				td:{
					attributes: 'colspan,rowspan',
					classes: 'td-source',
					styles:'border*,background-color,vertical-align,width,height',
				},
				tr:{
					attributes: '',
				},
			};
			
			returnConfig.extraPlugins = [
				// 'ajax',
				'autocorrect',
				'autogrow',
				'basicstyles',
				'button',
				'contextmenu',
				'paperDropOutlineNode',
				'paperDropResearchNote',
				'editorplaceholder',
				'enterkey',
				'fakeobjects',
				'figureTools',
				'indentParagraph',
				'justify',
				'lineutils',
				'list',
				'magicline',
				'pagebreak',
				'panel',
				'perrlaReferences',
				'printDocument',
				'removeformat',
				'saveDocument',
				'tableTools',
				'undo',
				'toolbar',
				'widgetselection',
				'wysiwygarea',
				'xml',
			];

			// add the citation context menu
			if(
				store.state.customer.citationAutoInsert &&
				(editorId === 'ckPaperAbstract' || editorId === 'ckPaperBody' || editorId === 'ckAppendices' || editorId === 'ckAnnotations') &&
				store.state.paperEdit.meta.UseNewCitationMode
			){
				returnConfig.extraPlugins.push('citationContextMenu');
			}

			// returnConfig.removePlugins = [];

			returnConfig.pasteFilter = {
				blockquote:{},
				caption:{},
				'figure-caption':{},
				'figure-title':{},
				h1:{
					classes: 'appendix-title'
				},
				h2:{},
				h3:{},
				h4:{},
				h5:{},
				p:{
					classes: 'blockquote-indent,figure-notes,no-indent',
				},
				span: {
					attributes: 'title',
					classes: 'figure-caption,figure-title,h3,h4,h5,table-caption,table-title,perrla_citation',
					styles: 'display'
				},	
				table:{
					attributes: 'align,border,cellpadding,cellspacing,summary',
					styles: 'width,height,border-collapse',
				},
				'table-caption':{},
				'table-title':{},
				tbody:{},
				tfoot:{},
				th:{},
				td:{
					attributes: 'colspan,rowspan',
					classes: 'td-source',
					styles:'border*,background-color,vertical-align,width,height',
				},
				tr:{
					attributes: '',
				},
			};

			returnConfig.autocorrect_recognizeUrls = false;
			returnConfig.autocorrect_formatBulletedLists = false;
			returnConfig.autocorrect_formatNumberedLists = false;

		}//e:if:else:ckPaperOutline
	
		return returnConfig;
	},//e:getSettings
	
}//e:export
