// extracted from https://ckeditor.com/cke4/addon/tabletools

export const insertColumn = (selectionOrCells, insertBefore) => {
	function getColumnsIndices( cells, isStart ) {
		var retval = isStart ? Infinity : 0;

		for ( var i = 0; i < cells.length; i++ ) {
			var colIndex = getCellColIndex( cells[ i ] );

			if ( isStart ? colIndex < retval : colIndex > retval ) {
				retval = colIndex;
			}
		}

		return retval;
	}
	
	function getCellColIndex( cell ) {
		var row = cell.getParent(),
			rowCells = row.$.cells;

		var colIndex = 0;
		for ( var i = 0; i < rowCells.length; i++ ) {
			var mapCell = rowCells[ i ];

			// Not always adding colSpan results in wrong position
			// of newly inserted column. (#591) (https://dev.ckeditor.com/ticket/13729)
			colIndex += mapCell.colSpan;
			if ( mapCell == cell.$ ) {
				break;
			}
		}
		return colIndex - 1;
	}
	
	//var cells = CKEDITOR.tools.isArray( selectionOrCells ) ? selectionOrCells : getSelectedCells( selectionOrCells ),
	let cells = selectionOrCells,
		firstCell = cells[ 0 ],
		table = firstCell.getAscendant( 'table' ),
		startCol = getColumnsIndices( cells, 1 ),
		lastCol = getColumnsIndices( cells ),
		colIndex = insertBefore ? startCol : lastCol,
		map = CKEDITOR.tools.buildTableMap( table ),
		cloneCol = [],
		nextCol = [],
		addedCells = [],
		height = map.length,
		originalCell;

	for ( var i = 0; i < height; i++ ) {
		var nextCell = insertBefore ? map[ i ][ colIndex - 1 ] : map[ i ][ colIndex + 1 ];

		cloneCol.push( map[ i ][ colIndex ] );
		nextCol.push( nextCell );
	}

	for ( i = 0; i < height; i++ ) {
		var cell;

		if ( !cloneCol[ i ] ) {
			continue;
		}

		// Check whether there's a spanning column here, do not break it.
		if ( cloneCol[ i ].colSpan > 1 && nextCol[ i ] == cloneCol[ i ] ) {
			cell = cloneCol[ i ];
			cell.colSpan += 1;
		} else {
			originalCell = new CKEDITOR.dom.element( cloneCol[ i ] );
			cell = originalCell.clone();
			cell.removeAttribute( 'colSpan' );
			cell.appendBogus();
			cell[ insertBefore ? 'insertBefore' : 'insertAfter' ].call( cell, originalCell );
			addedCells.push( cell );
			cell = cell.$;
		}

		i += cell.rowSpan - 1;
	}

	return addedCells;

}