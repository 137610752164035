// extracted from https://ckeditor.com/cke4/addon/tabletools

export const insertRow = (selectionOrCells, insertBefore) => {
	//let cells = CKEDITOR.tools.isArray( selectionOrCells ) ? selectionOrCells : getSelectedCells( selectionOrCells ),
	let cells = selectionOrCells,
		firstCell = cells[ 0 ],
		table = firstCell.getAscendant( 'table' ),
		doc = firstCell.getDocument(),
		startRow = cells[ 0 ].getParent(),
		startRowIndex = startRow.$.rowIndex,
		lastCell = cells[ cells.length - 1 ],
		endRowIndex = lastCell.getParent().$.rowIndex + lastCell.$.rowSpan - 1,
		endRow = new CKEDITOR.dom.element( table.$.rows[ endRowIndex ] ),
		rowIndex = insertBefore ? startRowIndex : endRowIndex,
		row = insertBefore ? startRow : endRow,
		map = CKEDITOR.tools.buildTableMap( table ),
		cloneRow = map[ rowIndex ],
		nextRow = insertBefore ? map[ rowIndex - 1 ] : map[ rowIndex + 1 ],
		width = map[ 0 ].length,
		newRow = doc.createElement( 'tr' );

	for ( let i = 0; cloneRow[ i ] && i < width; i++ ) {
		let cell;
		// Check whether there's a spanning row here, do not break it.
		if ( cloneRow[ i ].rowSpan > 1 && nextRow && cloneRow[ i ] == nextRow[ i ] ) {
			cell = cloneRow[ i ];
			cell.rowSpan += 1;
		} else {
			cell = new CKEDITOR.dom.element( cloneRow[ i ] ).clone();
			cell.removeAttribute( 'rowSpan' );
			cell.appendBogus();
			newRow.append( cell );
			cell = cell.$;
		}

		i += cell.colSpan - 1;
	}

	insertBefore ? newRow.insertBefore(row) : newRow.insertAfter(row);

	return newRow;
};