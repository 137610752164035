// extracted from https://ckeditor.com/cke4/addon/tabletools

export const getSelectedCells = (selection, table) => {
	var retval = [],
		database = {};

	if ( !selection ) {
		return retval;
	}

	var ranges = selection.getRanges();

	function isInTable( cell ) {
		if ( !table ) {
			return true;
		}

		return table.contains( cell ) && cell.getAscendant( 'table', true ).equals( table );
	}

	function moveOutOfCellGuard( node ) {
		var cellNodeRegex = /^(?:td|th)$/;

		// Apply to the first cell only.
		if ( retval.length > 0 ) {
			return;
		}

		// If we are exiting from the first </td>, then the td should definitely be
		// included.
		if ( node.type == CKEDITOR.NODE_ELEMENT && cellNodeRegex.test( node.getName() ) && !node.getCustomData( 'selected_cell' ) ) {
			CKEDITOR.dom.element.setMarker( database, node, 'selected_cell', true );
			retval.push( node );
		}
	}

	for ( var i = 0; i < ranges.length; i++ ) {
		var range = ranges[ i ];

		if ( range.collapsed ) {
			// Walker does not handle collapsed ranges yet - fall back to old API.
			var startNode = range.getCommonAncestor(),
				nearestCell = startNode.getAscendant( { td: 1, th: 1 }, true );

			if ( nearestCell && isInTable( nearestCell ) ) {
				retval.push( nearestCell );
			}
		} else {
			var walker = new CKEDITOR.dom.walker( range ),
				node;

			walker.guard = moveOutOfCellGuard;

			while ( ( node = walker.next() ) ) {
				// If may be possible for us to have a range like this:
				// <td>^1</td><td>^2</td>
				// The 2nd td shouldn't be included.
				//
				// So we have to take care to include a td we've entered only when we've
				// walked into its children.

				if ( node.type != CKEDITOR.NODE_ELEMENT || !node.is( CKEDITOR.dtd.table ) ) {
					var parent = node.getAscendant( { td: 1, th: 1 }, true );

					if ( parent && !parent.getCustomData( 'selected_cell' ) && isInTable( parent ) ) {
						CKEDITOR.dom.element.setMarker( database, parent, 'selected_cell', true );
						retval.push( parent );
					}
				}
			}
		}
	}

	CKEDITOR.dom.element.clearAllMarkers( database );

	return retval;
};