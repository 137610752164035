// import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import config from '@/config';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import PS_SaveDocument from '@/services/paper/saveDocument';
import store from '@/store';
// import TDS_Open from '@/services/paper/toolDrawer/open';

(() => {
	'use strict';

    CKEDITOR.plugins.add('perrlaReferences', {
		icons: 'delete,edit,showinpaper',
		hidpi: true,

		requires: "widget",
        init(editor) {
			editor.ui.addButton( 'PerrlaReferences', {
				label: 'Insert Citation',
				command: 'perrlaReferences',	// match the name of the widget you add
			});
			
			editor.widgets.add('perrlaReferences', {
				allowedContent: {
					span: {
						attributes: 'data-citation-unique-id, data-group-unique-id, data-reference-unique-id, replace-citation-unique-id', 
						classes: '!perrla_citation',
					},
				},
				inline:true,
				template: '<span class="perrla_citation">O</span>',

				data() {
					//	this.data is 'startupData'
					//	CKEDITOR.instances.ckPaperBody.execCommand('perrlaReferences', {
					//		startupData: insertData
					//	}); 

					// Insert					
					if(this.data.groupUniqueID){
						this.element.setAttribute("data-group-unique-id", this.data.groupUniqueID);
					} else {
						if(this.data.citationUniqueID){
							this.element.setAttribute("data-citation-unique-id", this.data.citationUniqueID);
						}
						if(this.data.referenceUniqueID){
							// console.log('this.data.referenceUniqueID');
							// console.log(this.data.referenceUniqueID);
							this.element.setAttribute("data-reference-unique-id", this.data.referenceUniqueID);
						}
					}
				},
				draggable: true,
				edit() {
					openCitationEditDrawer(editor);
				},
				init() {
					// Add widget editing option to its context menu.
					this.on('contextMenu', function (evt) {
						if(store.state.paperEdit.config.renderCitationsAsFootnotes){
							evt.data.jump_to_footnote = CKEDITOR.TRISTATE_OFF;
							evt.data.edit_footnote_text = CKEDITOR.TRISTATE_OFF;
						}
						evt.data.citation_delete = CKEDITOR.TRISTATE_OFF;
						evt.data.citation = CKEDITOR.TRISTATE_OFF;
					});
				},//init
				requiredContent: 'span(perrla_citation)',
				upcast(el) {
					if (el.name === 'span' && el.hasClass('perrla_citation')) {
						return el;
					}
				},//e:upcast
			});

			// CUSTOM Jump to Footnote in context menu
			editor.addCommand('cmdJumpToFootnote', CKEDITOR.tools.extend({
				exec(editor) {
					// console.log('CUSTOM Jump to Footnote in context menu')

					let path = editor.elementPath();
					let $citationEl = path.contains('span', 1);
					let $footnotesBlock = document.getElementById('paperFootnotesBlock');

					if (!$citationEl || !$footnotesBlock){
						return false;
					}

					if($citationEl.getFirst() && $citationEl.hasClass('cke_widget_perrlaReferences')){
						let citationUniqueId;
						
						if($citationEl.getFirst().hasAttribute('data-group-unique-id')){
							// merged citations
							citationUniqueId = $citationEl.getFirst().getAttribute('data-group-unique-id');

						} else if($citationEl.getFirst().hasAttribute('data-citation-unique-id')){
							// single citation
							citationUniqueId = $citationEl.getFirst().getAttribute('data-citation-unique-id');	
						}

						if(citationUniqueId){
							let footnoteInstanceNodeList = $footnotesBlock.querySelectorAll('div.paper-footnote-single');
								
							for (let i = 0; i < footnoteInstanceNodeList.length; i++) {
								if(footnoteInstanceNodeList[i]){
									let citationFindById = footnoteInstanceNodeList[i].getAttribute('citation-find-by-id');
									if(citationFindById){
										if(citationFindById === citationUniqueId){
											footnoteInstanceNodeList[i].scrollIntoView(true);
										}
									}
								}
							}//e:for
						}//e:citationUniqueId
						
					}//e::if
				}//e:exec
			}));//e:cmdJumpToFootnote

			// CUSTOM Delete Citation in context menu
			editor.addCommand('cmdCitationDelete', CKEDITOR.tools.extend({
				exec(editor) {
					let path = editor.elementPath();
					let $citationEl = path.contains('span', 1);

					if (!$citationEl){
						return false;
					}

					if($citationEl.getFirst() && $citationEl.hasClass('cke_widget_perrlaReferences')){
						let _citationElGetFirst = $citationEl.getFirst();					
						let saveMessage = '';	// resolves back so SaveDocument has a message of what was deleted

						let isGroupCitation = false;

						if(_citationElGetFirst){
							isGroupCitation = _citationElGetFirst.hasAttribute('data-group-unique-id');
						}

						if(isGroupCitation){
							// Group
							let groupUniqueId = _citationElGetFirst.getAttribute('data-group-unique-id');
							// console.log('groupUniqueId');
							// console.log(groupUniqueId);

							// get a list of single citations inside this group id
							let listOfCitationsInGroup = store.state.paperEdit.citations.inPaper.filter((citationData)=>{
								return (citationData.groupUniqueID) && (citationData.groupUniqueID.toUpperCase() === groupUniqueId.toUpperCase());
							});

							// loop through single citations in this group - remove each one from the vuex store
							listOfCitationsInGroup.forEach((citationObject)=>{
								let findCitationIndex = store.state.paperEdit.citations.inPaper.findIndex((citation)=>{
									return citation.citationData.citationUniqueID.toUpperCase() === citationObject.citationData.citationUniqueID.toUpperCase();
								});
								
								if(findCitationIndex !== -1){
									store.commit('paperEdit/citations/REMOVE_AT_INDEX', findCitationIndex);
								}
							});//e:forEach

						} else {
							// Single
							let citationUniqueId = _citationElGetFirst.getAttribute('data-citation-unique-id');
							
							// remove citation from vuex store
							let findCitationIndex = store.state.paperEdit.citations.inPaper.findIndex((citation)=>{
								return citation.citationData.citationUniqueID.toUpperCase() === citationUniqueId.toUpperCase();
							});

							if(findCitationIndex !== -1){
								store.commit('paperEdit/citations/REMOVE_AT_INDEX', findCitationIndex);
							}

							// remove footnote from vuex store
							if(store.state.paperEdit.config.renderCitationsAsFootnotes){
								store.commit('paperEdit/footnotes/REMOVE_AT_CITATION_UID', citationUniqueId.toUpperCase());
							}

						}//e:if:group|single

						// remove citation widget from paper body
						if (_citationElGetFirst && _citationElGetFirst.getParent()) {
							saveMessage = _citationElGetFirst.getHtml();

							let $elToInsert = new CKEDITOR.dom.element('span');
							$elToInsert.replace(_citationElGetFirst.getParent());
							
						} else {
							console.log('There was a problem removing the citation from this paper');
						}

						PS_BuildReferencesInPaper().then(()=>{
							PS_SaveDocument({
								isMetaDataChanges: true,
								message: 'Deleted Citation - ' + saveMessage,
							});
						});//e:PS_BuildReferencesInPaper

					}//e:if

				}//e:exec
			}));

			
			// CUSTOM Edit Citation in context menu
			editor.addCommand('cmdCitationEdit', CKEDITOR.tools.extend({
				exec(editor) {
					openCitationEditDrawer(editor);
                }//e:exec
			}));//e:addCommand:cmdCitationEdit

			
			// CUSTOM Edit Footnote Text in Modal
			editor.addCommand('cmdEditFootnoteText', CKEDITOR.tools.extend({
				exec(editor) {
					// console.log('CUSTOM Edit Footnote Text in Modal');

					let path = editor.elementPath();
					let $citationEl = path.contains('span', 1);

					if (!$citationEl){
						return false;
					}

					if($citationEl.getFirst() && $citationEl.hasClass('cke_widget_perrlaReferences')){
						let _citationElGetFirst = $citationEl.getFirst();					
						let citationUniqueId = _citationElGetFirst.getAttribute('data-citation-unique-id');
						// console.log('citationUniqueId');
						// console.log(citationUniqueId);
						if(window.$vm.$route.name === 'EditorCitationEdit' && window.$vm.$route.params.citationUid === citationUniqueId){
							// don't change route - user is already 'there'

						} else {
							window.$vm.$router.push({
								name: 'EditorCitationEdit',
								params: {
									citationUid: citationUniqueId,
									openCitationTab: config.enums.CitationTab.FOOTNOTE,
								}
							}).catch(()=>{});
						}

					}//e::if
                }//e:exec
			}));//e:addCommand:cmdEditFootnoteText

			// Register context menu option for editing widget.
			if (editor.addMenuItems) {
				editor.addMenuGroup('createref', 10);

				if(store.state.paperEdit.config.renderCitationsAsFootnotes){
					editor.addMenuItems({
						jump_to_footnote: {
							icon: 'showinpaper',
							label: 'Go to Footnote',
							group: 'createref',
							command: 'cmdJumpToFootnote',
							order: 3
						},
						citation: {
							icon: 'edit',
							label: 'Edit Footnote Citation',
							group: 'createref',
							command: 'cmdCitationEdit',
							order: 5
						},

						edit_footnote_text: {
							icon: 'edit',
							label: 'Edit Footnote Text',
							group: 'createref',
							command: 'cmdEditFootnoteText',
							order: 7
						},
						citation_delete: {
							icon: 'delete',
							label: 'Delete Footnote',
							group: 'createref',
							command: 'cmdCitationDelete',
							order: 9
						},
					});
				} else {
					editor.addMenuItems({
						citation: {
							icon: 'edit',
							label: 'Edit Citation',
							group: 'createref',
							command: 'cmdCitationEdit',
							order: 3
						},
						citation_delete: {
							icon: 'delete',
							label: 'Delete Citation',
							group: 'createref',
							command: 'cmdCitationDelete',
							order: 5
						},
					});

				}//e:if:else:renderCitationsAsFootnotes

			}//e:if:editor.addMenuItems
			
		},//e:init

		// saw this in the docs, might be cool to use later
		// afterInit(editor) {
		// 	console.log('afterInit');
		// },//e:afterInit

	});//e:plugins.add

	function openCitationEditDrawer(editor){
		let path = editor.elementPath();

		let $citationEl = path.contains('span', 1);

		if (!$citationEl){
			return false;
		}

		if($citationEl.hasClass('cke_widget_perrlaReferences')){
			let $citationNode = $citationEl.getFirst().$;
			let citationUniqueId = null;

			if($citationNode.hasAttribute('data-group-unique-id')){
				// group citation - go get the first citations uid then
				store.state.paperEdit.referencesInPaper.forEach((referenceObject)=>{
					if(_has(referenceObject, 'citations') && referenceObject.citations.length >= 1){
						citationUniqueId = referenceObject.citations[0].citationData.citationUniqueID;
					}
				});//e:forEach

			} else {
				// single citation, act normal
				citationUniqueId = $citationNode.getAttribute('data-citation-unique-id');
			}
			
			if(citationUniqueId){
				
				if(store.state.paperEdit.config.renderCitationsAsFootnotes){
					if(window.$vm.$route.name === 'EditorCitationEdit' && window.$vm.$route.params.citationUid === citationUniqueId){
						// don't change route - user is already 'there'
						
					} else {
						window.$vm.$router.push({
							name: 'EditorCitationEdit',
							params: {
								citationUid: citationUniqueId,
								openCitationTab: config.enums.CitationTab.CITATION,
							}
						}).catch(()=>{});
					}

				} else {
					if(window.$vm.$route.name === 'EditorCitationEdit' && window.$vm.$route.params.citationUid === citationUniqueId){
						// don't change route - user is already 'there'
						
					} else {
						window.$vm.$router.push({
							name: 'EditorCitationEdit',
							params: {
								citationUid: citationUniqueId,
								openCitationTab: config.enums.CitationTab.CITATION,
							}
						}).catch(()=>{});
					}
				}
			}
		}

	}//e:openCitationEditDrawer
	
})();
