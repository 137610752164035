// extracted from https://ckeditor.com/cke4/addon/tabletools


export const placeCursorInCell = (cell, placeAtEnd) => {
	let docInner = cell.getDocument();
	let docOuter = CKEDITOR.document;

	// Fixing "Unspecified error" thrown in IE10 by resetting
	// selection the dirty and shameful way (https://dev.ckeditor.com/ticket/10308).
	// We can not apply this hack to IE8 because
	// it causes error (https://dev.ckeditor.com/ticket/11058).
	if ( CKEDITOR.env.ie && CKEDITOR.env.version == 10 ) {
		docOuter.focus();
		docInner.focus();
	}

	var range = new CKEDITOR.dom.range( docInner );
	if ( !range[ 'moveToElementEdit' + ( placeAtEnd ? 'End' : 'Start' ) ]( cell ) ) {
		range.selectNodeContents( cell );
		range.collapse( placeAtEnd ? false : true );
	}
	range.select( true );
}