// this is a dumbed down version of the perrlaReferences plugin, since i just want to citation to appear here without any real functionality
(() => {
	'use strict';

    CKEDITOR.plugins.add('perrlaFootnotes', {
		requires: "widget",
        init(editor) {
			editor.ui.addButton( 'perrlaFootnotes', {
				label: 'PERRLA Footnoes',
				command: 'perrlaFootnotes',	// match the name of the widget you add
			});
			
			editor.widgets.add('perrlaFootnotes', {
				inline: true,
				template: '<span class="perrla_citation">O</span>',
				draggable: true,
				requiredContent: 'span(perrla_citation)',
				upcast(el) {
					if (el.name === 'span' && el.hasClass('perrla_citation')) {
						return el;
					}
				},//e:upcast
			});

		},//e:init

	});//e:plugins.add

	
})();
