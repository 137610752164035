/*
- Key listeners to emit events to CitationContextMenu.vue
*/
import config from '@/config';

'use strict';
(() => {
    CKEDITOR.plugins.add('citationContextMenu', {
        init(editor) {
            // console.log('citationContextMenu init()');
            // console.log(editor.name);
            
            editor.on('key', (event)=>{
                // console.log('window.$vm.$store.state.paperEdit.isCitationContextMenuOpen');
                // console.log(window.$vm.$store.state.paperEdit.isCitationContextMenuOpen);

                if(window.$vm.$store.state.paperEdit.isCitationContextMenuOpen){
                    // OPEN - any keypress will close the menu
                    window.$vm.$store.commit('paperEdit/SET_IS_CITATION_CONTEXT_MENU_OPEN', false);
                    
                } else {
                    // console.log('key');
                    // CLOSED - only listen for 

                    if(
                        event.data.keyCode === 2228281 && // Shift + 9 (open left parenthesis)
                        (window.$vm.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.APA7 || window.$vm.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.MLA9)
                    ){
                        window.$vm.emitter.emit('openCitationContextMenu', {
                            editor: editor,
                        });
                    } else if(
                        event.data.keyCode === 2228275 && // Shift + 3 (pound symbol #)
                        (window.$vm.$store.state.paperEdit.meta.PaperFormatVersionID === config.enums.Format.Turabian9)
                    ){
                        window.$vm.emitter.emit('openCitationContextMenu', {
                            editor: editor,
                        });
                    }//e:if:keyCode
                }//e:if:else
            });
        }//e:init
    });//e:plugins.add

})();