// only used to register drag/drop events in the Organize Tab > Outline - move this somewhere else if you get it to work
import config from '@/config';
import PCKS_WrapCitation from '@/services/paper/ck/wrapCitation';
import PS_BuildReferencesInPaper from '@/services/paper/buildReferencesInPaper';
import router from '@/router';
import store from '@/store';
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';

(() => {
	'use strict';

	CKEDITOR.plugins.add('outlineDropContent', {
		requires: "widget",

		init(editor) {
			editor.on('paste', (evt)=>{
				let researchNoteFull = evt.data.dataTransfer.getData('researchNoteFull');
				
				// console.log('researchNoteFull');
				// console.log(researchNoteFull);

				if (!researchNoteFull) {
					return;
				}
				let citationUniqueId = uuidv4().toUpperCase();

				if(researchNoteFull.citation){
					let citationResultObject = JSON.parse(researchNoteFull.citation);

					citationResultObject.citationData.referenceUniqueID = researchNoteFull.referenceUniqueID;

				
					let citationDataBase64 = window.btoa(JSON.stringify(citationResultObject).replace(/[\u00A0-\u2666]/g, function(c) {
						return '&#' + c.charCodeAt(0) + ';';
					}));
					

					evt.data.dataValue = researchNoteFull.note + '&nbsp;' +

					`<span class="perrla_citation"
							citation-data="` + citationDataBase64 + `"
							data-citation-unique-id="` + citationUniqueId + `"
							data-reference-unique-id="` + researchNoteFull.referenceUniqueID + `"
							data-reference-id="` + researchNoteFull.referenceID + `">O</span>`;

					Vue.nextTick(()=>{
						PCKS_WrapCitation({
							citationUniqueID: citationUniqueId,
						}).then(()=>{
							// editor.execCommand('autocorrect');
							PS_BuildReferencesInPaper();
							// return resolve();
						});
					});

				}//e:researchNoteFull.citation

				if(router.currentRoute.meta.tab === config.enums.Tab.OUTLINE){
					store.commit('paperEdit/researchNotes/OUTLINE_RESEARCH_NOTE_ADD_ACTIVE', researchNoteFull.researchNoteId);
				}

				

			});//e:on.paste
		}//e:init
	});//e:plugins.add

})();
