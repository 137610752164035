'use strict';
(()=>{
	CKEDITOR.plugins.add( 'toolbar', {
		requires: 'button',
		lang: 'en',

		init(editor){
			editor.on('uiSpace', (event)=>{
				// Create toolbar only once.
				event.removeListener();

				// Object containing all toolbar groups used by ui items.
				let lookup = {};
				let itemName, item, itemToolbar, group, order;
				
				for (itemName in editor.ui.items) {
					item = editor.ui.items[ itemName ];
					itemToolbar = item.toolbar || 'others';
					
					if (itemToolbar){
						// Break the toolbar property into its parts: "group_name[,order]".
						itemToolbar = itemToolbar.split( ',' );
						group = itemToolbar[ 0 ];
						order = parseInt( itemToolbar[ 1 ] || -1, 10 );

						// Initialize the group, if necessary.
						lookup[ group ] || ( lookup[ group ] = [] );

						// Push the data used to build the toolbar later.
						lookup[ group ].push( { name: itemName, order: order } );
					}

				}//e:for

				// Put the items in the right order.
				for (group in lookup) {
					lookup[group] = lookup[group].sort((a,b)=>{
						return a.order == b.order ? 0 :
							b.order < 0 ? -1 :
							a.order < 0 ? 1 :
							a.order < b.order ? -1 :
							1;
					});
				}//e:for
				
				// console.log('lookup');
				// console.log(lookup);

				// Take the base for the new toolbar, which is basically a toolbar
				// definition without items.
				var toolbar = editor._.toolbarGroups || (editor._.toolbarGroups = [
					{ name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
					{ name: 'paragraph',   groups: [ 'list', 'indent', 'blocks', 'align'] },
					{ name: 'others' }
				]);

				// Fill the toolbar groups with the available ui items.
				for (var i = 0; i < toolbar.length; i++){
					var toolbarGroup = toolbar[ i ];

					// Skip toolbar break.
					if ( toolbarGroup == '/' )
						continue;
					// Handle simply group name item.
					else if ( typeof toolbarGroup == 'string' )
						toolbarGroup = toolbar[ i ] = { name: toolbarGroup };

					var items, subGroups = toolbarGroup.groups;

					// Look for items that match sub groups.
					if (subGroups) {
						for ( var j = 0, sub; j < subGroups.length; j++ ) {
							sub = subGroups[ j ];

							// If any ui item is registered for this subgroup.
							items = lookup[ sub ];
							items && fillGroup( toolbarGroup, items );
						}
					}

					// Add the main group items as well.
					items = lookup[ toolbarGroup.name ];
					items && fillGroup( toolbarGroup, items );

				}//e:for

				function fillGroup( toolbarGroup, uiItems ) {
					if ( uiItems.length ) {
						if ( toolbarGroup.items )
							toolbarGroup.items.push( editor.ui.create( '-' ) );
						else
							toolbarGroup.items = [];

						var item, name;
						while ( ( item = uiItems.shift() ) ) {
							name = typeof item == 'string' ? item : item.name;

						
							item = editor.ui.create( name );

							if ( !item )
								continue;

							if ( !editor.addFeature( item ) )
								continue;

							toolbarGroup.items.push( item );
							
						}
					}
				}//e:fillGroup

			});//e:uiSpace
		}//e:init
	});//e:add
})();